import React, { Component } from "react"

import HeaderOffset from "../components/header/headeroffset"
import ColumnContent from "../components/columncontent"
import ContactForm from "../components/contactform"

import Layout from "../components/layout"
import * as styles from '../styles/pages/contact.module.css'

import defaultdata from "../content/contactdetails.json"

const apiurl = "https://dataset.boysencrm.com/";

class ContactPage extends Component {
	state = {
		contactdata: defaultdata,
		wdhtratio: 1,
		fontsizeclass: "size",
		titleclass: ""
	};
	componentDidMount() {
		this.setState(state => ({
			wdhtratio: 	window.innerWidth/window.innerHeight,
			fontsizeclass: 	window.innerWidth/window.innerHeight <= 1? "fixedmobile":"size",
			titleclass: 	window.innerWidth/window.innerHeight <= 1? "text-centered":"",
		}));
		fetch(apiurl+'contactdetails.json')
			.then(res => res.json())
			.then(data => this.setState({contactdata: data})
			);
	}

	render() {
		return (
			<Layout activePath='contact' pageTitle="Contact Us" lockDisplayType={true}>
				<div className={this.state.wdhtratio > 1? styles.containerdesktop : styles.containermobile}>
					<div className={styles.content}>
						<HeaderOffset/>
						<h2 className={"font-"+this.state.fontsizeclass+"-large margin-title"}>
							CONTACT US
						</h2>
						<ColumnContent countword={this.state.wdhtratio > 1? "two": "one"} >
							<h3 className={"font-"+this.state.fontsizeclass+"-regular "+this.state.titleclass+" "+styles.contacttitle }>Send us a Message</h3>
							<ContactForm mobileDisplay={this.state.wdhtratio > 1? false: true} showLabel={false}/>
						</ColumnContent>
						{ this.state.wdhtratio <= 1 && <>
								<hr className={styles.contactdivider}/>
							</>
						}
						<ColumnContent countword={this.state.wdhtratio > 1? "two": "one"} >
							<h3 className={"font-"+this.state.fontsizeclass+"-regular "+this.state.titleclass+" "+styles.contacttitle }>Office Details</h3>

							<h3 className={"font-"+this.state.fontsizeclass+"-regular "+this.state.titleclass+" "+styles.contacttitle +" "+ styles.contacttitlesecondary}>Main Office</h3>
							<ContactDetails fontsizeclass={this.state.fontsizeclass} object={this.state.contactdata.main} />

							<h3 className={"font-"+this.state.fontsizeclass+"-regular "+this.state.titleclass+" "+styles.contacttitle +" "+ styles.contacttitlesecondary}>Marketing and Technical Service</h3>
							<ContactDetails fontsizeclass={this.state.fontsizeclass} object={this.state.contactdata.marketing} />
							{
								this.state.contactdata.social &&
								<div className={styles.sociallist}>
									<h3 className={"font-"+this.state.fontsizeclass+"-regular "+this.state.titleclass+" "+styles.contacttitle +" "+ styles.contacttitlesecondary}>Follow Us</h3>
									<SocialIcons object={this.state.contactdata.social} />
								</div>
							}
						</ColumnContent>
					</div>
				</div>
			</Layout>
		);
	}
}


const ContactDetails = ({ fontsizeclass, object }) => {
	return (
		<div className={"font-"+fontsizeclass+"-small "+styles.contactdetailarea}>
		{	object.address &&
			<div className={(fontsizeclass==="size"? "": "text-centered") + " "+styles.contactdetail + " font-style-bold " + styles.contactdetailaddress}>
				{object.address.map((data, index) => {
					return <div >{data}</div>
				})}
			</div>
		}
		{ object.hotline &&
			<ContactList
				textalignclass={fontsizeclass==="size"? "": "text-centered"}
				object={object.hotline}
				title="Hotline"
				separator="; "
				classname={styles.contactdetailhotline} />
		}
		{ object.landline &&
			<ContactList
				textalignclass={fontsizeclass==="size"? "": "text-centered"}
				object={object.landline}
				title="Tel. Nos"
				separator="; "
				classname={styles.contactdetaillandline} />
		}
		{ object.fax &&
			<ContactList
				textalignclass={fontsizeclass==="size"? "": "text-centered"}
				object={object.fax}
				title="Fax Nos"
				separator="; "
				classname={styles.contactdetailfax} />
		}
		{ object.mobile &&
			<ContactList
				textalignclass={fontsizeclass==="size"? "": "text-centered"}
				object={object.mobile}
				title="Mobile"
				separator="; "
				classname={styles.contactdetailmobile} />
		}
		{ object.email &&
			<ContactList
				textalignclass={fontsizeclass==="size"? "": "text-centered"}
				object={object.email}
				title=""
				separator="; "
				classname={styles.contactdetailemail} />
		}
		{ object.website &&
			<ContactWebsite
				textalignclass={fontsizeclass==="size"? "": "text-centered"}
				fontsizeclass={fontsizeclass}
				object={object.website}
				title="Website"
				separator="; "
				classname={styles.contactdetailwebsite} />
		}
		</div>
	)
}

const ContactList = ({ textalignclass, title, separator, classname, object }) => {
	return (
		<div className={styles.contactdetail + " "+textalignclass+ " " + classname}>
			{title.length>0?title+": ":""}
			{object.map((data, index) => {
				return <span>{index>0?separator:""}{data}</span>
			})}
		</div>
	)
}

const ContactWebsite = ({ textalignclass, fontsizeclass, title, separator, classname, object }) => {
	return (
		<div className={styles.contactdetail + " "+textalignclass+" " + classname}>
			{title.length>0?title+": ":""}
			{object.map((data, index) => {
				return <span>{index>0?separator:""}<a className={"font-"+fontsizeclass+"-small"} href={"https://"+data} target="_blank" rel="noreferrer">{data}</a></span>
			})}
		</div>
	)
}

const SocialIcons = ({ object }) => {
	return (
		<>
			{object.map((data, index) => {
				return <a
							className={styles.socialicon}
							href={data.url}
							target="_blank"
							title={data.name}
							rel="noreferrer"
						>
							<img src={apiurl+data.img} alt={data.name}  />
						</a>
			})}
		</>
	)
}

export default ContactPage
