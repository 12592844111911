// extracted by mini-css-extract-plugin
export var contactdetail = "contact-module--contactdetail--44470";
export var contactdetailaddress = "contact-module--contactdetailaddress--ab657";
export var contactdetailarea = "contact-module--contactdetailarea--0529e";
export var contactdetailemail = "contact-module--contactdetailemail--a8fe4";
export var contactdetailfax = "contact-module--contactdetailfax--47c10";
export var contactdetailhotline = "contact-module--contactdetailhotline--c6c2e";
export var contactdetaillandline = "contact-module--contactdetaillandline--b192c";
export var contactdetailmobile = "contact-module--contactdetailmobile--b7803";
export var contactdetailwebsite = "contact-module--contactdetailwebsite--4955f";
export var contactdivider = "contact-module--contactdivider--4ab65";
export var contacttitle = "contact-module--contacttitle--ad3f9";
export var contacttitlesecondary = "contact-module--contacttitlesecondary--0879f";
export var containerdesktop = "contact-module--containerdesktop--66589";
export var containermobile = "contact-module--containermobile--38dcd";
export var content = "contact-module--content--3264b";
export var socialicon = "contact-module--socialicon--1ff75";
export var sociallist = "contact-module--sociallist--59bab";